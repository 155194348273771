<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-tab
        :dense="true"
        type="pagetop"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        setHeight="500px"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            dense
            :is="tab.component"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
export default {
  name: 'washing-facility-plan-tab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      tab: 'washingFacilityPlanInfo',
      tabItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.tabItems = [
        // 세척·세안 시설
        { name: 'washingFacilityPlanInfo', icon: 'info', label: 'LBL0010546', component: () => import(`${'./washingFacilityPlanInfo.vue'}`) }, // 세척·세안 시설
        // 세척·세안 시설
        { name: 'washingFacilityPlanSafety', icon: 'info', label: 'LBL0010547', component: () => import(`${'./washingFacilityPlanSafety.vue'}`) }, // 안전보호장구/비상방제물품
        // 도면
        { name: 'washingFacilityPlanPid', icon: 'tab', label: 'LBL0010548', component: () => import(`${'./washingFacilityPlanPid.vue'}`) }, // 세척·세안 시설 및 안전 보호장구 설치계획도
        // 문서
        { name: 'washingFacilityPlanDoc', icon: 'assignment', label: 'LBL0010549', component: () => import(`${'./washingFacilityPlanDoc.vue'}`) } // 세척·세안 시설 및 안전 보호장구 설치계획 문서
      ]
    },

  }
};
</script>